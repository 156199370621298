<template>
  <div class="">
    <p class="mb-3">
      Seleziona la tipologia di segnalazione che si sta effettuando.
    </p>
    <p class="mb-4">
      In seguito, seleziona la voce che corrisponde alla tua situazione.
    </p>
    <h5 class="heading--dark">Seleziona la tipologia di segnalazione:</h5>
    <b-input-group class="mt-2">
      <div class="d-flex w-100">
        <div class="flex-grow-1">
          <v-select
            id="feedbackType"
            label="value"
            placeholder="Selezionare una voce"
            v-model="reportDetail.feedbackType"
            :options="feedbackTypes"
            :reduce="item => item.key"
            v-on:option:selected="loadQuestions"
          >
          </v-select>
        </div>
      </div>
    </b-input-group>
  </div>
</template>

<script>

import { isPresent } from '@/utils/validators';

export default {
  name: 'ReportContextSelection',
  props: {
    reportDetail: {
      feedbackType: String,
      feedbackQuestion: String,
      feedbackDetail: String,
      note: String,
      response: String,
      reportAmount: {
        currency: String,
        amount: Number,
      },
    },
  },
  created() {
    if (!this.$store.getters['tableUtils/hasUserReportType'] && !this.$store.getters['tableUtils/isLoadingUserReportType']) {
      this.$store.dispatch('tableUtils/loadUserReportType', 'it').then(() => {
        console.log('loaded UserReportType');
      });
    }
  },
  computed: {
    feedbackTypes() {
      return this.$store.getters['tableUtils/getUserReportType'];
    },
  },
  methods: {
    loadQuestions() {
      console.log('loadQuestions', this.reportDetail.feedbackType);
      if (isPresent(this.reportDetail.feedbackType)) {
        this.reportDetail.feedbackQuestion = null;
        if (!this.$store.getters['tableUtils/hasTableList'](this.reportDetail.feedbackType)) {
          if (!this.$store.getters['tableUtils/isLoadingTableList'](this.reportDetail.feedbackType)) {
            this.$store.dispatch('tableUtils/loadTableList', {
              tableName: this.reportDetail.feedbackType,
              language: 'it',
            });
          }
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
